(function($) {

    "use strict";

    var PageHelper = {

        v 						: "v"

		, initMobileNavigation: function(){
          $('#nav-icon').click(function(){
            $(this).toggleClass('open');
            $('body').toggleClass('nav-open');
          });
		}
		, initDesktopNavigation: function(){
            PageHelper.calculateDesktopNavigationPosition();

			$('#menu-navigation .menu-item-has-children').on('mouseenter', function(){
                PageHelper.calculateDesktopNavigationPosition();
				$(this).find('.sub-menu-wrap').addClass('active');
			});
			$('#menu-navigation .menu-item-has-children').on('mouseleave', function(){
				$(this).find('.sub-menu-wrap').removeClass('active');
         	});


		}

        , calculateDesktopNavigationPosition : function(){

            $('#menu-navigation .menu-item-has-children').each(function(i,e){

                var li              = $(e)
                ,   submenu         = li.find('.sub-menu')
                ,   leftValue       = parseInt(li.position().left)
                ,   lia             = li.children('a')
                ,   marginValue     = parseInt(lia.css( "margin-left"))
                ,   paddingVaue     = parseInt(lia.css( "padding-left"))
                ,   totalLeftValue  = leftValue + marginValue + paddingVaue
                ;
/*
                console.log(leftValue)
                console.log(marginValue)
                console.log(paddingVaue)
                console.log(totalLeftValue)
*/
                submenu.css({
                    'margin-left'  : totalLeftValue

                })

            });
        }

    };

    var ready_func = function() {
        window.PageHelper = PageHelper;
        //PageHelper.init();
    };

    if ($.isReady) {
        ready_func();
    } else {
        $(document).ready(ready_func);
    }

})(jQuery);